import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IHelp, HelpType } from '../../interfaces/iHelp';
import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/Rx';
import { ILookup } from '../../interfaces/lookup';
import { HelpService } from '../../services/help.service';
import { EnumHelper } from '../../helpers/enum.helper';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { HelpNewDialogComponent } from './new/help.new.component';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  public helpColumns = ['isActive', 'title', 'category', 'updatedDate', 'updatedBy'];
  public dataSource = new MatTableDataSource([]);

  public allHelpTypes: ILookup[] = [];

  constructor(
    private _helpService: HelpService,
    private _router: Router,
    public dialog: MatDialog
  ) {
    this.allHelpTypes = EnumHelper.toLookupDictionary(HelpType);
  }

  public ngOnInit(): void {
    this._helpService.getAll().then(results => {
      this.dataSource = new MatTableDataSource(results);
      this.dataSource.sort = this.sort;
    });
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  public onClickRow(row: IHelp) {
    this._router.navigate(['/help/' + row.id]);
  }

  public onNew() {
    this._router.navigate(['/help/0']);
  }
}
