import { ConfirmationDialogComponent } from './../../../confirmation/confirmation.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { TenantsService } from './../../../../services/tenants.service';
import { ContactsService } from './../../../../services/contacts.service';
import { ITenant } from '../../../../interfaces/iTenant';
import { IContact } from '../../../../interfaces/iContacts';
import { TenantContactEditDialogComponent } from './edit/edit.contacts.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tenant-detail-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class TenantDetailContactsComponent implements OnInit, OnChanges {
  @Input() tenant: ITenant;

  public contactColumns = ['contactId', 'fullName', 'email', 'department', 'phoneDirect', 'phoneCell', 'commands'];
  private _contactData: BehaviorSubject<IContact[]> = new BehaviorSubject<IContact[]>([]);
  public contactDataSource = new ContactDataSource(this._contactData.asObservable());

  constructor(
    private _tenantsService: TenantsService, 
    private _contactsService: ContactsService, 
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _router: Router
  ){}
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    // Check to see if the Tenant input is changing and has a valid value
    if (changes['tenant'] && changes['tenant'].currentValue) {
      this.getData();
    }
  }

  private getData(): void {
    this._contactsService.getById(this.tenant.id).then(results => {
      this._contactData.next(results);
    });
  }

  public onClickEditContact(contact: IContact): void {
    console.log(contact);
    const dialogRef = this.dialog.open(TenantContactEditDialogComponent, {
      width: '500px',
      data: contact
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  public onClickDeleteContact(contact: IContact): void {
    this._contactsService.delete(contact.id).then(
      (newContact: IContact) => {
        this._snackBar.open('Deleted!', 'Close message', {
          duration: 2000
        });
        this.refeshData();
      },
      err => {
        console.error(err);
      }
    );
  }

  public refeshData(): void {
    this.getData();
  }
}

export class ContactDataSource extends DataSource<any> {
  private _data: Observable<IContact[]>;

  constructor(data: Observable<IContact[]>) {
    super();
    this._data = data;
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<IContact[]> {
    return this._data;
  }

  disconnect() {}
}
