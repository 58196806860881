import { Component, OnInit, Input } from '@angular/core';
import { AuditDataSource } from '../../tenants/detail/tenant.detail.component';
import * as moment from 'moment';

export interface ITooltipAudit {
  date: Date;
  value: string;
  changedBy: string;
}

@Component({
  selector: 'app-tooltip-audit',
  templateUrl: './tooltip-audit.component.html',
  styleUrls: ['./tooltip-audit.component.css']
})
export class TooltipAuditComponent implements OnInit {
  @Input() target: any;
  @Input() field: string;
  @Input() auditDataSource: AuditDataSource;

  public data: ITooltipAudit[] = [];

  constructor() {}

  ngOnInit() {
    this.auditDataSource.connect().subscribe(audits => {
      this.data = [];

      if (audits.length > 0) {
        let lastCheckValue = audits[0][this.field];

        // Start on the second row, the first should be our current values
        for (let i = 1; i < audits.length; ++i) {
          const audit = audits[i];
          const auditValue = audit[this.field];

          const lastAudit = audits[i - 1];
          const lastAuditValue = lastAudit[this.field];

          if (auditValue !== lastCheckValue) {
            this.data.push({
              date: moment(lastAudit['updatedDate']).toDate(),
              value: lastAuditValue.length > 0 ? lastAuditValue : '- BLANK -',
              changedBy: audit['updatedBy']
            });

            lastCheckValue = auditValue;
          }

          if (i === audits.length - 1 && auditValue === lastAuditValue && this.data.length > 0) {
            this.data.push({
              date: moment(audit['updatedDate']).toDate(),
              value: auditValue.length > 0 ? auditValue : '- BLANK -',
              changedBy: audit['updatedBy']
            });
          }
        }
      }
    });
  }
}
