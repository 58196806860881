import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgForm } from '@angular/forms';
import { INews, NewsPostType } from './../../../interfaces/iNews';
import { ReleaseNotesService } from './../../../services/releasenotes.service';
import { EnumHelper } from '../../../helpers/enum.helper';
import { ILookup } from './../../../interfaces/lookup';
import { IReleaseNote } from '../../../interfaces/iReleaseNote';
import * as marked from 'marked';
import { AdalService } from '../../../services/adal/adal.service';
import { MatSnackBar } from '@angular/material';
import { IMarkdownData } from '../../markdown-editor/markdown-editor.component';
import { MarkdownHelper } from '../../../helpers/markdown.helper';

@Component({
  selector: 'app-releasenotes-detail',
  templateUrl: './releasenotes.detail.component.html',
  styleUrls: ['./releasenotes.detail.component.scss']
})
export class ReleaseNotesDetailComponent implements OnInit {
  public releaseNote: IReleaseNote;
  public markdownMode: string = 'editor';
  public markdownOptions: any = {
    hideIcons: ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen'] // default is empty, the value is case-sensitive
  };
  @ViewChild('releasenotesForm') releasenotesForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    private _releaseNoteService: ReleaseNotesService,
    private _adalService: AdalService,
    public _router: Router,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .switchMap((params: ParamMap) => {
        const id = parseInt(params.get('id'));
        if (id === 0) {
          return new Promise<IReleaseNote>((resolve, reject) => {
            resolve(this._releaseNoteService.getEmpty());
          });
        } else {
          return this._releaseNoteService.getById(parseInt(params.get('id'))).then(result => result);
        }
      })
      .subscribe((releaseNote: IReleaseNote) => {
        this.releaseNote = releaseNote;
      });
  }

  save(): void {
    //this.releaseNote.releaseNotes = marked(this.releaseNote.markdown);
    this.releaseNote.updatedBy = this._adalService.userInfo.username;
    if (this.releaseNote.id === 0) {
      this.releaseNote.createdBy = this._adalService.userInfo.username;
      this._releaseNoteService.post(this.releaseNote).then(
        (newReleaseNote: IReleaseNote) => {
          this._snackBar.open('Created!', 'Close message', {
            duration: 2000
          });
          this._router.navigate(['/releasenotes/' + newReleaseNote.id]);
        },
        err => {
          console.error(err);
        }
      );
    } else {
      this._releaseNoteService.put(this.releaseNote).then(
        () => {
          this._snackBar.open('Saved!', 'Close message', {
            duration: 2000
          });
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  public onToggleStatus(): void {
    this.releaseNote.isActive = !this.releaseNote.isActive;
    this._releaseNoteService.put(this.releaseNote).then(
      () => {
        this._snackBar.open('Saved!', 'Close message', {
          duration: 2000,
        });
      },
      err => {
        console.error(err);
      }
    );
  }

  public handleMarkdownChanged(data: IMarkdownData): void {
    this.releaseNote.markdown = 'not needed';
    this.releaseNote.releaseNotes = data.value;
    this.releaseNote.markdownImages = data.valueImages;
    //this.releaseNote.releaseNotes = MarkdownHelper.renderWithImages(data);
  }
}
