﻿import { environment } from './../environments/environment';
/* ===== app/services/backup.service.ts ===== */
import { Injectable, Inject } from '@angular/core';
import { IBackup, BackupType } from '../interfaces/iBackup';
import { v4 } from 'uuid';
import { AdalService } from './adal/adal.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BackupsService {
  private _url: string = '';

  constructor(@Inject('BASE_URL') baseUrl: string, private _http: HttpClient) {
    this._url = environment.restTarget + '/api/backup';
  }

  public getEmpty(): IBackup {
    const backup: IBackup = {
      name: '',
      description: '',
      timestamp: null,
      type: BackupType.None
    };

    return backup;
  }

  public getBackupsJsonById(id: number | null): Promise<IBackup[]> {
    if (id === null) {
      return Promise.reject('No Tenant Id.');
    }

    return new Promise((resolve, reject) => {
      this._http.get<IBackup[]>(this._url + '/' + id).subscribe(
        results => {
          resolve(results);
        },
        error => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  public getBackupsListById(id: number | null): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get<string[]>(this._url + '/' + id).subscribe(
        results => {
          // Map the results to the IBackup interface
          const backups = results.map(b => {
            const backup: IBackup = {
              name: b,
              description: '',
              timestamp: new Date(Date.parse(b)),
              type: BackupType.None
            };

            return backup;
          });

          // Sort the backups in descending date order
          const sortedBackups = backups.sort((a, b) => {
            const aDate = a.timestamp.getTime();
            const bDate = b.timestamp.getTime();
            return bDate - aDate;
          });

          resolve(sortedBackups);
        },
        error => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  public put(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put(this._url + '/' + id, null).subscribe(
        (result: any) => {
          resolve();
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  public restoreBackup(id: number | null, body: string) {
    return new Promise((resolve, reject) => {
      this._http
        .post(this._url + '/restore/request/' + id, body)
        .map(() => {})
        .subscribe(
          () => {
            resolve();
          },
          error => {
            console.error(error);
            reject(error);
          }
        );
    });
  }

  public downloadBackup(id: number | null, body: string) {
    return new Promise((resolve, reject) => {
      this._http
        .post(this._url + '/download/' + id, body)
        .subscribe(
          results => {
            resolve(results);
          },
          error => {
            console.error(error);
            reject(error);
          }
        );
    });
  }
}
