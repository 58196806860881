import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TenantsService } from '../../../services/tenants.service';
import { BackupsService } from '../../../services/backups.service';
import { ContactsService } from '../../../services/contacts.service';
import { ITenant } from '../../../interfaces/iTenant';
import { IBackup } from '../../../interfaces/iBackup';
import { DataSource } from '@angular/cdk/collections';
import 'rxjs/add/operator/switchMap';

import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { MatDialog, MatDialogRef, DialogPosition, MatSnackBar, MatTabChangeEvent } from '@angular/material';
import { ContactsNewDialogComponent } from './contacts/new/contacts.new.component';
import { AdalService } from '../../../services/adal/adal.service';
import { TenantDetailContactsComponent } from './contacts/contacts.component';

@Component({
  selector: 'app-tenant-detail',
  templateUrl: './tenant.detail.component.html',
  styleUrls: ['./tenant.detail.component.scss']
})
export class TenantDetailComponent implements OnInit {
  [x: string]: any;
  public tenant: ITenant;
  public audits: ITenant[];
  public backups: any[];
  public isContactActive: boolean;
  public licenseTypes: any[];
  @ViewChild('tenantForm') tenantForm: NgForm;
  @ViewChild(TenantDetailContactsComponent) tenantDetailContactsComponent: TenantDetailContactsComponent;

  public auditColumns = [
    'updatedDate',
    'updatedBy',
    'name',
    'status',
    'adminFirstName',
    'adminLastName',
    'adminEmail',
    'adminPhoneDirect',
    'adminPhoneCell',
    'customerId',
    'physicalAddress1',
    'physicalAddress2',
    'physicalAddressCity',
    'physicalAddressState',
    'physicalAddressZip',
    'licenseType',
    'licensesSubmitterFull',
    'licensesFull',
    'readonlyLicenses'
  ];

  private _auditData: BehaviorSubject<ITenant[]> = new BehaviorSubject<
    ITenant[]
  >([]);
  public auditDataSource = new AuditDataSource(this._auditData.asObservable());

  constructor(
    private route: ActivatedRoute,
    private _tenantsService: TenantsService,
    private _contactsService: ContactsService,
    private _backupsService: BackupsService,
    private _adalService: AdalService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .switchMap((params: ParamMap) =>
        this._tenantsService.getById(parseInt(params.get('id'))).then(result => result)
      )
      .subscribe((tenant: any) => {
        tenant.createdDate = new Date(Date.parse(tenant.createdDate));
        this.tenant = tenant;
      });
   
    this._tenantsService.getLicenseTypes().then(
      (res) => {
        this.licenseTypes = res;
        console.log(this.licenseTypes);
      },
      err => {
        console.log(err);
      }
    );
  
    // this.route.paramMap
    //   .switchMap((params: ParamMap) =>
    //     this._tenantsService.getAuditById(parseInt(params.get('id'))).then((result) => {
    //       return result;
    //     })
    //   )
    //   .subscribe((audits: ITenant[]) => {
    //     this.audits = audits;
    //     this._auditData.next(audits);
    //   });
  }
  saveCustomer(): void {
    const date = new Date();
    const putTenant: any = this.tenant;
    putTenant.updatedDate = date.toISOString();

    this._tenantsService.put(putTenant).then(
      () => {
        this.tenant = putTenant;
        this._snackBar.open('Saved!', 'Close message', {
          duration: 2000,
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  onSetTenantStatus(status: boolean): void {
    this.tenant.suspended = status;
    this.saveCustomer();
  }

  backupCustomer(): void {
    this._backupsService.put(this.tenant.id).then(
      () => {
        this._snackBar.open('Backup Started!', 'Close message', {
          duration: 2000,
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  public addContact() {
    const dialogRef = this.dialog.open(ContactsNewDialogComponent, {
      width: '400px',
      data: this._contactsService.getEmpty()
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const results = result;
        result.updatedBy = this._adalService.userInfo.username;
        result.createdBy = this._adalService.userInfo.username;
        result.tenantId = this.tenant.id;
        this._contactsService.post(result).then(newContact => {
          this._snackBar.open('Created!', 'Close message', {
            duration: 2000
          });
          this.tenantDetailContactsComponent.refeshData();
        });
      }
    });
  }

  onToggleAudits(value: boolean): void {
    this.showAudits = value;
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index == 2) {
      this.isContactActive = true;  
    }
    else {
      this.isContactActive = false;
    }
  }
}

export class AuditDataSource extends DataSource<any> {
  private _data: Observable<ITenant[]>;

  constructor(data: Observable<ITenant[]>) {
    super();
    this._data = data;
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<ITenant[]> {
    return this._data;
  }

  disconnect() {}
}
