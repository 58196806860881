import { ILookup } from '../interfaces/lookup';

export class EnumHelper {
    constructor() {

    }

    static toLookupDictionary(type: any): ILookup[] {
        const lookups: ILookup[] = [];

        for (const item in type) {
            if (isNaN(Number(item))) {
                lookups.push({ text: item, value: type[item] });
            }
        }

        return lookups;
    }
}
