import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IReleaseNote } from '../../interfaces/iReleaseNote';
import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/Rx';
import { ReleaseNotesService } from '../../services/releasenotes.service';
import { EnumHelper } from '../../helpers/enum.helper';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { ReleaseNotesNewDialogComponent } from './new/releasenotes.new.component';

@Component({
  selector: 'app-releasenotes',
  templateUrl: './releasenotes.component.html',
  styleUrls: ['./releasenotes.component.css']
})
export class ReleaseNotesComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  public releaseNotesColumns = ['isActive', 'releaseDate', 'version', 'title'];
  public dataSource = new MatTableDataSource([]);

  constructor(private _releaseNoteService: ReleaseNotesService, private _router: Router, public dialog: MatDialog) {}

  public ngOnInit(): void {
    this._releaseNoteService.getAll().then(results => {
      this.dataSource = new MatTableDataSource(results);
      this.dataSource.sort = this.sort;
    });
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  public onClickRow(row: IReleaseNote) {
    this._router.navigate(['/releasenotes/' + row.id]);
  }

  public onNew() {
    this._router.navigate(['/releasenotes/0']);
  }
}
