export enum NewsPostType {
  Security = 0,
  Compliance = 1,
  IndustryNews = 2,
  Offers = 3
}

export interface INews {
  id: number;
  type: NewsPostType;
  title: string;
  subject: string;
  article: string;
  markdown: string;
  markdownImages?: string;
  postDate: string;
  isActive: boolean;
  updatedBy: string;
  createdBy: string;
}
