import { IContact } from './../../../../../interfaces/iContacts';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { ContactsService } from '../../../../../services/contacts.service';

@Component({
  selector: 'app-tenants-contacts-edit-dialog',
  templateUrl: './edit.contacts.component.html',
  styleUrls: ['./edit.contacts.component.scss']
})
export class TenantContactEditDialogComponent implements OnInit {
  public processing = false;

  constructor(
    public dialogRef: MatDialogRef<TenantContactEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IContact,
    private _contactsService: ContactsService
  ) {

  }

  public ngOnInit(): void {}

  public onClickCancel(): void {
    this.dialogRef.close();
  }

  public onClickSave(): void {
    this.processing = true;
    this._contactsService.put(this.data).then(results => {
      this.dialogRef.close();
    });
  }
}
