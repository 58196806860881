/* ===== app/services/auth.service.ts ===== */
import { Injectable, Inject } from '@angular/core';
import { ITenant } from '../interfaces/iTenant';
import { v4 } from 'uuid';
import { environment } from './../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { IUser } from '../interfaces/iUsers';

@Injectable()
export class TenantsService {
  private _url: string = '';
  private _options: any;

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private _http: HttpClient
  ) {
    this._url = environment.restTarget + '/tenant';
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    this._options = { headers: headers, observe: 'response' };
  }

  public getEmpty(): ITenant {
    const tenant: ITenant = {
      id: -1,
      suspended: false,
      status: '',
      customerId: '',
      name: '',
      physicalAddress1: '',
      physicalAddress2: '',
      physicalAddressCity: '',
      physicalAddressState: '',
      physicalAddressZip: '',
      adminEmail: '',
      adminUsername: '',
      adminFirstName: '',
      adminLastName: '',
      licenseType: '',
      licensesFull: 0,
      licensesAssigned: 0,
      licensesSubmitterFull:0,
      adminPhoneDirect: '',
      adminPhoneCell: '',
      readonlyLicenses:0
    };

    return tenant;
  }

  public getAll(): Promise<ITenant[]> {
    return new Promise((resolve, reject) => {
      this._http.get<ITenant[]>(this._url, this._options).subscribe(
        results => {
          const data = results as HttpResponse<ITenant[]>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getById(id: number | null): Promise<ITenant> {
    if (id === null) {
      return Promise.reject('No Tenant Id.');
    }

    return new Promise((resolve, reject) => {
      const target = this._url + '/' + id;
      this._http.get<ITenant>(target, this._options)
      .subscribe(
        result => {
          const data = result as HttpResponse<ITenant>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getAuditById(id: number | null): Promise<ITenant[]> {
    if (id === null) {
      return Promise.reject('No Tenant Id.');
    }

    return new Promise((resolve, reject) => {
      this._http.get<ITenant[]>(this._url + '/audit/' + id, this._options).subscribe(
        results => {
          const data = results as HttpResponse<ITenant[]>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public post(body: ITenant): Promise<ITenant> {
    return new Promise((resolve, reject) => {
      this._http.post<ITenant>(this._url, JSON.stringify(body), this._options).subscribe(
        result => {
          const data = result as HttpResponse<ITenant>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public put(body: ITenant): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put<ITenant>(this._url + '/' + body.id, JSON.stringify(body), this._options).subscribe(
        result => {
          const data = result as HttpResponse<ITenant>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getAllUsersForTenant(id: number | null): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get<IUser[]>(this._url + '/users/' + id, this._options).subscribe(
        result => {
          const data = result as HttpResponse<IUser[]>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getUserLoginsForTenant(id: number | null): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get<number>(this._url + '/logins/' + id, this._options)
      .subscribe(
        result => {
          const data = result as HttpResponse<number>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getAllContractsForTenant(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get<number>(this._url + '/contracts/' + id, this._options).subscribe(
        result => {
          const data = result as HttpResponse<number>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getUserCount(id: number | null): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get<number>(this._url + '/users/count/' + id, this._options).subscribe(
        result => {
          const data = result as HttpResponse<number>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getUserLogins(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get<number>(this._url + '/logins/', this._options).subscribe(
        result => {
          const data = result as HttpResponse<number>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public setTenantOwner(tenantId: number, userId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.post<any>(this._url + `/users/owner/${tenantId}/${userId}`, this._options).subscribe(
        result => {
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getLicenseTypes(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get<number>(this._url + '/licensetypes/', this._options).subscribe(
        result => {
          const data = result as HttpResponse<number>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }





  //public getSoftwareLicenseTypes(): Promise<any> {
  //  return new Promise((resolve, reject) => {
  //    this._http.get<number>(this._url + '/typesoflicenses/', this._options).subscribe(
  //      result => {
  //        const data = result as HttpResponse<number>;
  //        resolve(data.body);
  //      },
  //      error => {
  //        reject(error);
  //      }
  //    );
  //  });
  //}





}
