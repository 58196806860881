﻿export enum BackupType {
  None, // Default
  Hourly, // Within 24 hours
  Daily, // Within 7 days but more than a day
  Weekly, // Within 4 weeks but more than 7 days
  Monthly, // Within 12 months but more than current month
  Yearly // Within 7 years but more than one year
}

export interface IBackup {
    name: string;
    description: string;
    timestamp: Date;
    type: BackupType;
}
