import '@progress/kendo-ui';
import { AfterViewInit, OnDestroy, ViewChild, ElementRef, Renderer, Component, OnInit } from '@angular/core';
import { HTMLEditorComponent } from '../html-editor/html-editor.component';
import { HelpService } from '../../services/help.service';
import * as $ from 'jquery';

declare var kendo: any;

@Component({
    selector: 'app-image-treeview',   
    templateUrl: './image-treeview.component.html', 
    // template: `
    //   <kendo-treeview [nodes]="allImages"></kendo-treeview>`,
    styleUrls: ['./image-treeview.component.scss']
  })

export class ImageTreeviewComponent implements OnInit, AfterViewInit, OnDestroy {
    private _that = this;
    private _editor: HTMLEditorComponent;
    private _dragNode: kendo.data.Node;
    public allImages: string[];
    private allImageNames: string[];

    @ViewChild('fieldList') imageList;
    private _kendoTreeView: any;
    private treeViewOptions = {
        loadOnDemand: false,
        dataTextField: 'url',
        dataBound: this.onDataBound.bind(this._that)

    }
    constructor(
        private hostEl: ElementRef, 
        private renderer: Renderer, 
        private _helpService: HelpService,
    ) {}

    ngOnInit(): void {
        let images = [];
        let names = [];
        var results = this._helpService.getAllHelpFiles().then(results => {
            for (let result of results) {
                images.push(result.url);
                names.push(result.name);
            }
        });
        this.allImages = images;
        
    }
    
    ngAfterViewInit() {
        this._kendoTreeView = kendo.jQuery(this.imageList.nativeElement) 
            .kendoTreeView(this.treeViewOptions)
            .data('kendoTreeView');
            let body = $(this._editor.textarea.nativeElement).find('iframe').contents().find('body');
    }
    ngOnDestroy(): void {
        kendo.destroy(this.hostEl.nativeElement);
    }

    onDataBound(e: kendo.ui.TreeViewDataBoundEvent): void {
        let items = e.sender.items();
        for (let item of items) {
            let dataItem = e.sender.dataItem(item);

            kendo.jQuery(item).children().first().attr('draggable', 'true');
        }
    }
}

