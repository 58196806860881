import { ILookup } from './../../../interfaces/lookup';
import { EnumHelper } from './../../../helpers/enum.helper';
import { HelpType } from './../../../interfaces/iHelp';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-help-new-dialog',
  templateUrl: './help.new.component.html',
  styleUrls: ['./help.new.component.scss']
})
export class HelpNewDialogComponent {
  public allHelpTypes: ILookup[] = [];

  constructor(
    public dialogRef: MatDialogRef<HelpNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.allHelpTypes = EnumHelper.toLookupDictionary(HelpType);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
