import { ILookup } from './../../../interfaces/lookup';
import { EnumHelper } from './../../../helpers/enum.helper';
import { NewsPostType } from './../../../interfaces/iNews';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-news-new-dialog',
  templateUrl: './news.new.component.html',
  styleUrls: ['./news.new.component.scss']
})
export class NewsNewDialogComponent {
  public allNewsTypes: ILookup[] = [];

  constructor(
    public dialogRef: MatDialogRef<NewsNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.allNewsTypes = EnumHelper.toLookupDictionary(NewsPostType);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
