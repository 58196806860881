﻿import { BaseChartDirective } from 'ng2-charts';
import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { AppInsightsService } from '../../../services/app.insights.service';
import { IChartOptions } from '../../../interfaces/chartOptions';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-server-response',
  templateUrl: './server-response.component.html',
  styleUrls: ['./server-response.component.css', '../card.css']
})
export class ServerResponseComponent implements OnInit {
  private _timeFormat = 'MM/DD/YYYY HH:mm';

  public timeSpans = [
    { value: 0, timeSpan: 'PT3H', interval: 'PT15M', text: '3 Hours' },
    { value: 1, timeSpan: 'PT6H', interval: 'PT30M', text: '6 Hours' },
    { value: 2, timeSpan: 'PT12H', interval: 'PT1H', text: '12 Hours' },
    { value: 3, timeSpan: 'P1D', interval: 'PT2H', text: '1 Day' },
    { value: 4, timeSpan: 'P3D', interval: 'PT6H', text: '3 Days' },
    { value: 5, timeSpan: 'P7D', interval: 'PT14H', text: '7 Days' },
  ];
  public selectedTimeSpan = this.timeSpans[1].value;


  public charts: IChartOptions[] = [
    {
      name: 'Avg Availability',
      target: 'availabilityResults/availabilityPercentage',
      targetProperty: 'avg',
      timeSpan: this.timeSpans[this.selectedTimeSpan].timeSpan,
      interval: this.timeSpans[this.selectedTimeSpan].interval,
      display: false,
      type: 'line',
      showLegend: false,
      value: 0,
      valueSuffix: '%',
      datasets: [{ data: [0, 0, 0, 0, 0, 0, 0] }],
      colors: [
        {
          backgroundColor: 'rgba(68, 158, 50, 0.5)',
          borderColor: 'rgb(32, 125, 168)',
          pointBackgroundColor: 'rgb(68, 158, 50)',
          pointBorderColor: 'rgba(255, 255, 255, 0.8)',
          pointHoverBackgroundColor: 'rgb(68, 158, 50)',
          pointHoverBorderColor: 'rgba(255, 255, 255, 0.8)'
        }
      ],
      labels: [],
      options: {
        responsive: true,
        elements: { line: { tension: 0, borderWidth: 1 } },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                parser: this._timeFormat,
                // round: 'day'
                tooltipFormat: 'll HH:mm'
              },
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9,
                padding: 4,
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                  if (Math.floor(label) === label) {
                    return label + '%';
                  }
                }
              }
            }
          ]
        }
      }
    },
    {
      name: 'Avg Server Response Time',
      target: 'availabilityResults/duration',
      targetProperty: 'avg',
      timeSpan: this.timeSpans[this.selectedTimeSpan].timeSpan,
      interval: this.timeSpans[this.selectedTimeSpan].interval,
      display: false,
      type: 'line',
      showLegend: false,
      value: 0,
      valueSuffix: ' ms',
      datasets: [{ data: [0, 0, 0, 0, 0, 0, 0] }],
      colors: [
        {
          backgroundColor: 'rgba(167, 39, 206, 0.5)',
          borderColor: 'rgb(126, 28, 156)',
          pointBackgroundColor: 'rgb(167, 39, 206)',
          pointBorderColor: 'rgba(255, 255, 255, 0.8)',
          pointHoverBackgroundColor: 'rgb(167, 39, 206)',
          pointHoverBorderColor: 'rgba(255, 255, 255, 0.8)'
        }
      ],
      labels: [],
      options: {
        responsive: true,
        elements: { line: { tension: 0, borderWidth: 1 } },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                parser: this._timeFormat,
                // round: 'day'
                tooltipFormat: 'll HH:mm'
              },
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9,
                padding: 4,
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                  if (Math.floor(label) === label) {
                    return label + ' ms';
                  }
                }
              }
            }
          ]
        }
      }
    },
    {
      name: 'Total Requests',
      target: 'requests/count',
      targetProperty: 'sum',
      timeSpan: this.timeSpans[this.selectedTimeSpan].timeSpan,
      interval: this.timeSpans[this.selectedTimeSpan].interval,
      display: false,
      type: 'bar',
      showLegend: false,
      value: 0,
      valueSuffix: '',
      datasets: [{ data: [0, 0, 0, 0, 0, 0, 0] }],
      colors: [
        {
          backgroundColor: 'rgba(39, 153, 206, 0.5)',
          borderColor: 'rgb(79, 34, 105)',
          pointBackgroundColor: 'rgba(39, 153, 206, 0.5)',
          pointBorderColor: 'rgba(255, 255, 255, 0.8)',
          pointHoverBackgroundColor: 'rgb(39, 153, 206)',
          pointHoverBorderColor: 'rgba(255, 255, 255, 0.8)'
        }
      ],
      labels: [],
      options: {
        responsive: true,
        elements: { line: { tension: 0, borderWidth: 1 } },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                parser: this._timeFormat,
                // round: 'day'
                tooltipFormat: 'll HH:mm'
              },
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9,
                padding: 4,
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                  if (Math.floor(label) === label) {
                    return label;
                  }
                }
              }
            }
          ]
        }
      }
    },
    {
      name: 'Failed Requests',
      target: 'requests/failed',
      targetProperty: 'sum',
      timeSpan: this.timeSpans[this.selectedTimeSpan].timeSpan,
      interval: this.timeSpans[this.selectedTimeSpan].interval,
      display: false,
      type: 'bar',
      showLegend: false,
      value: 0,
      valueSuffix: '',
      datasets: [{ data: [0, 0, 0, 0, 0, 0, 0] }],
      colors: [
        {
          backgroundColor: 'rgba(206, 39, 39, 0.5)',
          borderColor: 'rgb(146, 29, 29)',
          pointBackgroundColor: 'rgb(206, 39, 39)',
          pointBorderColor: 'rgba(255, 255, 255, 0.8)',
          pointHoverBackgroundColor: 'rgb(206, 39, 39)',
          pointHoverBorderColor: 'rgba(255, 255, 255, 0.8)'
        }
      ],
      labels: [],
      options: {
        responsive: true,
        elements: { line: { tension: 0, borderWidth: 1 } },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                parser: this._timeFormat,
                // round: 'day'
                tooltipFormat: 'll HH:mm'
              },
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9,
                padding: 4,
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                  if (Math.floor(label) === label) {
                    return label;
                  }
                }
              }
            }
          ]
        }
      }
    }
  ];

  constructor(private _appInsService: AppInsightsService) {}

  ngOnInit(): void {
    this.getData();
  }

  private getData(): void {
    this.charts.forEach(chart => {
      this._appInsService.getMetricResults(chart.target, chart.timeSpan, chart.interval).then(resp => {
        this.postProcessData(chart, resp.value);
      });

      this._appInsService.getMetricResults(chart.target, chart.timeSpan).then(resp => {
        chart.value = resp.value[chart.target][chart.targetProperty];
        chart.display = true;
      });
    });
  }

  public postProcessData(chart: IChartOptions, data: any): void {
    const dataset = {
      label: chart.name,
      fill: true,
      data: []
    };

    const labels: Date[] = [];
    for (let i = 0; i < data.segments.length; ++i) {
      labels.push(new Date(data.segments[i].start));
      dataset.data.push(data.segments[i][chart.target][chart.targetProperty]);
    }

    chart.labels = labels;
    chart.datasets[0] = dataset;
  }

  public onClickRefresh(): void {
    this.getData();
  }

  public onTimeSpanSelectionChange(): void {
    this.charts.forEach(chart => {
      chart.timeSpan = this.timeSpans[this.selectedTimeSpan].timeSpan;
      chart.interval = this.timeSpans[this.selectedTimeSpan].interval;
    });

    this.getData();
  }

  // // events
  // public onChartClicked(e: any): void {
  //   console.log(e);
  // }

  // public onChartHovered(e: any): void {
  //   console.log(e);
  // }
}
