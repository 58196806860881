﻿/* ===== app/services/auth.service.ts ===== */
import { Injectable, Inject } from '@angular/core';
import { v4 } from 'uuid';
import { environment } from './../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { IUser } from '../interfaces/iUsers';

@Injectable()
export class UsersService {
  private _url: string = '';
  private _options: any;

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private _http: HttpClient
  ) {
    this._url = environment.restTarget + '/api/users';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    this._options = { headers: headers, observe: 'response' };
  }

  public getEmpty(): IUser {
    const user: IUser = {
      userId: -1,
      userIdentifier: '',
      tenantId: -1,
      tenantOwner: false,
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      suspended: false
    };

    return user;
  }

  public getAll(): Promise<IUser[]> {
    return new Promise((resolve, reject) => {
      this._http.get<IUser[]>(this._url, this._options).subscribe(
        results => {
          const data = results as HttpResponse<IUser[]>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getById(id: number | null): Promise<IUser> {
    if (id === null) {
      return Promise.reject('No Tenant Id.');
    }

    return new Promise((resolve, reject) => {
      const target = this._url + '/' + id;
      this._http.get<IUser>(target, this._options)
      .subscribe(
        result => {
          const data = result as HttpResponse<IUser>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public post(body: IUser): Promise<IUser> {
    return new Promise((resolve, reject) => {
      this._http.post<IUser>(this._url, JSON.stringify(body), this._options).subscribe(
        result => {
          const data = result as HttpResponse<IUser>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public put(body: IUser): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put<IUser>(this._url + '/' + body.userId, JSON.stringify(body), this._options).subscribe(
        result => {
          const data = result as HttpResponse<IUser>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
