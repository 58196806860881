﻿import { environment } from './../environments/environment';
/* ===== app/services/auth.service.ts ===== */
import { Injectable, Inject } from '@angular/core';
import { AdalService } from './adal/adal.service';
import { v4 } from 'uuid';
import { IReleaseNote } from '../interfaces/iReleaseNote';
import * as moment from 'moment';
import { HttpResponse, HttpClient } from '@angular/common/http';

@Injectable()
export class ReleaseNotesService {
  private _url: string = '';
  private _options: any;

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private _http: HttpClient
  ) {
    this._url = environment.restTarget + '/api/releasenotes';
    this._options = { observe: 'response' };
  }

  public getEmpty(): IReleaseNote {
    const note: IReleaseNote = {
      id: 0,
      title: '',
      version: '',
      markdown: '',
      releaseNotes: '',
      releaseDate: new Date().toISOString(),
      isActive: true
    };

    return note;
  }

  public getAll(): Promise<IReleaseNote[]> {
    return new Promise((resolve, reject) => {
      this._http.get<IReleaseNote[]>(this._url + '/all', this._options).subscribe(
        results => {
          const data = results as HttpResponse<IReleaseNote[]>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getById(id: number | null): Promise<IReleaseNote> {
    if (!id) {
      return Promise.reject('No Release Notes Id.');
    }

    return new Promise((resolve, reject) => {
      const target = this._url + '/details/' + id;
      this._http.get<IReleaseNote>(target, this._options)
      .subscribe(
        result => {
          const data = result as HttpResponse<IReleaseNote>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public post(releaseNote: IReleaseNote): Promise<any> {
    if (!releaseNote) {
      return Promise.reject('No release notes data.');
    }

    return new Promise((resolve, reject) => {
      this._http.post<IReleaseNote>(this._url, JSON.stringify(releaseNote), this._options).subscribe(
        result => {
          const data = result as HttpResponse<IReleaseNote>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public put(releaseNote: IReleaseNote): Promise<any> {
    if (!releaseNote) {
      return Promise.reject('No release notes data.');
    }

    return new Promise((resolve, reject) => {
      this._http.put<IReleaseNote>(this._url + '/' + releaseNote.id, JSON.stringify(releaseNote), this._options).subscribe(
        result => {
          const data = result as HttpResponse<IReleaseNote>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
