import { IUser } from './../../../../../interfaces/iUsers';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { UsersService } from '../../../../../services/users.service';

@Component({
  selector: 'app-tenants-users-edit-dialog',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class TenantUserEditDialogComponent implements OnInit {
  public processing = false;

  constructor(
    public dialogRef: MatDialogRef<TenantUserEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUser,
    private _usersService: UsersService
  ) {

  }

  public ngOnInit(): void {}

  public onClickCancel(): void {
    this.dialogRef.close();
  }

  public onClickSave(): void {
    this.processing = true;
    this._usersService.put(this.data).then(results => {
      this.dialogRef.close();
    });
  }
}
