import { Component, OnInit } from '@angular/core';
import { AdalService } from '../../services/adal/adal.service';
import * as moment from 'moment';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './navmenu.component.html',
    styleUrls: ['./navmenu.component.css']
})
export class NavMenuComponent implements OnInit {
    public userProfile: any;
    public expiration: moment.Moment;

    constructor(private _adalService: AdalService) {
    }

    ngOnInit(): void {
        if (this._adalService.isAuthenticated() && this._adalService.userInfo.profile) {
          this.userProfile = this._adalService.userInfo.profile;
          this.expiration = moment(new Date(this.userProfile['exp'] * 1000));
        }
    }

    logout(): void {
        this._adalService.logOut();
    }

    login(): void {
      this._adalService.login();
    }

    isAuthenticated(): boolean {
        return this._adalService.isAuthenticated();
    }

    hasProfileImage(): boolean {
      return this.userProfile && this.userProfile['picture'];
    }

    getExpiration(): string {
      return this.expiration ? this.expiration.fromNow() : '(refreshing)';
    }
}
