export enum HelpType {
  System,
  Test
}

export interface IHelp {
  id: number;
  title: string;
  category: string;
  keywords: string;
  markdown: string;
  markdownImages?: string;
  renderedMarkdown: string;
  isActive: boolean;
  createdDate?: string;
  createdBy?: string;
  updatedDate?: string;
  updatedBy?: string;
}
