import { Pipe, PipeTransform } from '@angular/core';
import { ILookup } from '../interfaces/lookup';

/*
 * Get the Text from the Lookup Value
 * Takes an argument of the list of possible lookups
 * Usage:
 *   value | lookup:ILookup[]
 * Example:
 *   {{ '1' | lookup:[{ value: '1', text: 'Test 1'}, { value: '2', text: 'Test 2'}] }}
 *   formats to:
*/
@Pipe({name: 'lookup'})
export class LookupPipe implements PipeTransform {
  transform(value: string, lookups: ILookup[]): string {
    const lookup = lookups.find((item) => {
        return item.value === value;
    });

    return lookup ? lookup.text : value;
  }
}
