import { ConfirmationDialogComponent } from './../../../confirmation/confirmation.component';
import { MatDialog } from '@angular/material';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { TenantsService } from './../../../../services/tenants.service';
import { ITenant } from '../../../../interfaces/iTenant';
import { IUser } from '../../../../interfaces/iUsers';
import { TenantUserEditDialogComponent } from './edit/edit.component';

@Component({
  selector: 'app-tenant-detail-users',
  templateUrl: 'users.component.html',
  styleUrls: ['./users.component.scss']
})
export class TenantDetailUsersComponent implements OnInit, OnChanges {
  @Input() tenant: ITenant;

  public userColumns = ['userId', 'suspended', 'tenantOwner', 'userName', 'email', 'userIdentifier', 'command'];
  private _userData: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([]);
  public userDataSource = new UserDataSource(this._userData.asObservable());

  constructor(private _tenantsService: TenantsService, public dialog: MatDialog) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    // Check to see if the Tenant input is changing and has a valid value
    if (changes['tenant'] && changes['tenant'].currentValue) {
      this.getData();
    }
  }

  private getData(): void {
    this._tenantsService.getAllUsersForTenant(this.tenant.id).then(results => {
      this._userData.next(results);
    });
  }

  public onClickEditUser(user: IUser): void {
    console.log(user);
    const dialogRef = this.dialog.open(TenantUserEditDialogComponent, {
      width: '500px',
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  public onClickMakeOwner(user: IUser): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you wish to make this person the Tenant Owner?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'Confirm') {
        this._tenantsService.setTenantOwner(this.tenant.id, user.userId).then(() => {
          this.getData();
        });
      }
    });
  }
}

export class UserDataSource extends DataSource<any> {
  private _data: Observable<IUser[]>;

  constructor(data: Observable<IUser[]>) {
    super();
    this._data = data;
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<IUser[]> {
    return this._data;
  }

  disconnect() {}
}
