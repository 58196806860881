﻿import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from './../environments/environment';
/* ===== app/services/backup.service.ts ===== */
import { Injectable, Inject } from '@angular/core';
import { IHelp, HelpType } from '../interfaces/iHelp';
import { IHelpFile } from '../interfaces/iHelpFile';
import { v4 } from 'uuid';
import * as moment from 'moment';
import { AdalService } from './adal/adal.service';
import { IHelpUploadFile } from '../interfaces/iHelpUploadFile';

@Injectable()
export class HelpService {
  private _url: string = '';
  private _options: any;

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private _http: HttpClient
  ) {
    this._url = environment.restTarget + '/api/help';
    this._options = { observe: 'response' };
  }

  public getEmpty(): IHelp {
    const help: IHelp = {
      id: 0,
      title: '',
      category: '',
      keywords: '',
      markdown: '',
      renderedMarkdown: '',
      isActive: true
    };

    return help;
  }

  public getAll(): Promise<IHelp[]> {
    return new Promise((resolve, reject) => {
      this._http.get<IHelp[]>(this._url + '/all', this._options).subscribe(
        results => {
          const data = results as HttpResponse<IHelp[]>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getAllHelpFiles(): Promise<IHelpFile[]> {
    return new Promise((resolve, reject) => {
      this._http.get<[IHelpFile]>(this._url + '/helpfiles', this._options).subscribe(
        results => {
          const data = results as HttpResponse<[IHelpFile]>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getById(id: number | null): Promise<IHelp> {
    if (!id) {
      return Promise.reject('No Help Id.');
    }

    return new Promise((resolve, reject) => {
      const target = this._url + '/details/' + id;
      this._http.get<IHelp>(target, this._options)
      .subscribe(
        result => {
          const data = result as HttpResponse<IHelp>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public post(help: IHelp): Promise<any> {
    if (!help) {
      return Promise.reject('No Help data.');
    }

    return new Promise((resolve, reject) => {
      this._http.post<IHelp>(this._url, JSON.stringify(help), this._options).subscribe(
        result => {
          const data = result as HttpResponse<IHelp>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public put(help: IHelp): Promise<any> {
    if (!help) {
      return Promise.reject('No Help data.');
    }

    return new Promise((resolve, reject) => {
      this._http.put<IHelp>(this._url + '/' + help.id, JSON.stringify(help), this._options).subscribe(
        result => {
          const data = result as HttpResponse<IHelp>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public delete(id: Number): Promise<any> {
    if (!id) {
      return Promise.reject('No Help Id.');
    }

    return new Promise((resolve, reject) => {
      const target = this._url + '/delete/' + id;
      this._http.delete<IHelp>(target, this._options)
      .subscribe(
        result => {
          const data = result as HttpResponse<IHelp>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
