﻿/* ===== app/services/app.insights.service.ts ===== */
import { environment } from './../environments/environment';
import { Injectable, Inject } from '@angular/core';
import { AdalService } from './adal/adal.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable()
export class AppInsightsService {
  private _options: any;

  private _url = `https://${environment.appInsightsConfig.host}/beta/apps/${environment.appInsightsConfig.appId}/`;

  constructor(private _http: HttpClient) {
    const headers = new HttpHeaders().set('x-api-key', environment.appInsightsConfig.apiKey);
    this._options = { headers: headers, observe: 'response' };
  }

  public getUsersCount(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get<any>(this._url + 'metrics/users/count?timespan=P1D&interval=PT1H', this._options).subscribe(
        result => {
          const data = result as HttpResponse<any>;
          resolve(data.body);
        },
        error => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  public getServerRequestsDuration(): Promise<any> {
    return new Promise((resolve, reject) => {
      // default for timespan is 12 hours
      this._http.get<any>(this._url + 'metrics/requests/duration', this._options).subscribe(
        result => {
          const data = result as HttpResponse<any>;
          resolve(data.body);
        },
        error => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  public getServerRequestsCount(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get<any>(this._url + 'metrics/requests/count', this._options).subscribe(
        result => {
          const data = result as HttpResponse<any>;
          resolve(data.body);
        },
        error => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  public getServerRequestsFailed(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get<any>(this._url + 'metrics/requests/failed', this._options).subscribe(
        result => {
          const data = result as HttpResponse<any>;
          resolve(data.body);
        },
        error => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  public getServerExceptions(timespan: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get<any>(this._url + 'metrics/exceptions/server?timespan=' + timespan, this._options).subscribe(
        result => {
          const data = result as HttpResponse<any>;
          resolve(data.body);
        },
        error => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  // https://api.applicationinsights.io/v1/apps/DEMO_APP/metrics/availabilityResults/duration?timespan=PT12H&interval=PT5M
  public getMetricResults(type: string, timespan: string, interval?: string, segment?: string): Promise<any> {
    const fullInterval = interval ? '&interval=' + interval : '';
    const fullSegment = segment ? '&segment=' + encodeURI(segment) : '';
    return new Promise((resolve, reject) => {
      this._http.get<any>(this._url + 'metrics/' + type + '?timespan=' + timespan + fullInterval + fullSegment, this._options).subscribe(
        result => {
          const data = result as HttpResponse<any>;
          resolve(data.body);
        },
        error => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
}
