/* ===== app/services/auth.service.ts ===== */
import { Injectable, Inject } from '@angular/core';
import { v4 } from 'uuid';
import { environment } from './../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { IContact } from '../interfaces/iContacts';

@Injectable()
export class ContactsService {
  private _url: string = '';
  private _options: any;

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private _http: HttpClient
  ) {
    this._url = environment.restTarget + '/api/tenantcontacts';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    this._options = { headers: headers, observe: 'response' };
  }

  public getEmpty(): IContact {
    const contact: IContact = {
      id: 0,
      department: '',
      tenantId: -1,
      firstName: '',
      lastName: '',
      email: '',
      phoneDirect: '',
      phoneCell: ''
    };

    return contact;
  }

  public getById(id: number | null): Promise<any> {
    if (id === null) {
      return Promise.reject('No Tenant Id.');
    }

    return new Promise((resolve, reject) => {
      const target = this._url + '/' + id;
      this._http.get<IContact[]>(target, this._options)
      .subscribe(
        result => {
          const data = result as HttpResponse<IContact[]>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public post(body: IContact): Promise<IContact> {
    return new Promise((resolve, reject) => {
      this._http.post<IContact>(this._url, JSON.stringify(body), this._options).subscribe(
        result => {
          const data = result as HttpResponse<IContact>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public put(body: IContact): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.put<IContact>(this._url + '/' + body.id, JSON.stringify(body), this._options).subscribe(
        result => {
          const data = result as HttpResponse<IContact>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public delete(id: Number): Promise<any> {
    if (!id) {
      return Promise.reject('No Contact Id.');
    }

    return new Promise((resolve, reject) => {
      const target = this._url + '/' + id;
      this._http.delete<IContact>(target, this._options)
      .subscribe(
        result => {
          const data = result as HttpResponse<IContact>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
