import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { INews, NewsPostType } from '../../interfaces/iNews';
import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/Rx';
import { ILookup } from '../../interfaces/lookup';
import { NewsService } from '../../services/news.service';
import { EnumHelper } from '../../helpers/enum.helper';
import { MatDialog, MatSort, MatTableDataSource } from '@angular/material';
import { NewsNewDialogComponent } from './new/news.new.component';

@Component({
  selector: 'app-settings',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  public newsColumns = ['isActive', 'type', 'postDate', 'title', 'subject'];
  public dataSource = new MatTableDataSource([]);

  public allNewsTypes: ILookup[] = [];

  constructor(private _newsService: NewsService, private _router: Router, public dialog: MatDialog) {
    this.allNewsTypes = EnumHelper.toLookupDictionary(NewsPostType);
  }

  public ngOnInit(): void {
    this._newsService.getAll().then(results => {
      this.dataSource = new MatTableDataSource(results);
      this.dataSource.sort = this.sort;
    });
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  public onClickRow(row: INews) {
    this._router.navigate(['/news/' + row.id]);
  }

  public onNew() {
    this._router.navigate(['/news/0']);
  }
}
