import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Rx';
import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import * as marked from 'marked';
import { MarkdownHelper } from '../../helpers/markdown.helper';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MarkdownEditorImageDropdownComponent } from './image-dropdown/image-dropdown.component';
import { ESCAPE } from '@angular/cdk/keycodes';
import { merge } from 'rxjs/observable/merge';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
  selector: 'app-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss']
})
export class MarkdownEditorComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('editor') textarea: ElementRef;
  @Input() value: string;
  @Input() valueImages: string;
  @Input() height: string;

  @Output() valueChanged = new EventEmitter<IMarkdownData>();

  @ViewChild('insertImages') insertImagesButton: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  public showPreview = true;
  public renderedMarkdown = '';
  public images: IMarkdownImage[] = [];

  private _markdownUpdate: Subject<string> = new Subject<string>();
  private _markdownObs: Observable<string> = this._markdownUpdate.asObservable();
  private _markdownEditSub: Subscription;

  private _imageDropdownOverlayRef: OverlayRef;
  private _imageDropdownOverlaySub: Subscription;

  constructor(private _overlay: Overlay) {}

  ngOnInit() {
    this._markdownEditSub = this._markdownObs.debounceTime(600).subscribe(value => {
      this.updateRenderedMarkdown();
      this.updateModel();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] && this.value) {
      this.updateRenderedMarkdown();
    }

    if (changes['valueImages'] && this.valueImages) {
      const markdownImages = this.valueImages.substr(1, this.valueImages.length).split('\n');
      this.images = [];
      markdownImages.forEach(i => {
        const nameLength = i.indexOf(']:') - 1;
        this.images.push({
          name: i.substr(1, nameLength),
          value: i.substr(nameLength + 4, i.length)
        });
      });
      this.updateRenderedMarkdown();
    }
  }

  public ngOnDestroy(): void {
    if (this._markdownEditSub) {
      this._markdownEditSub.unsubscribe();
    }

    if (this._imageDropdownOverlaySub) {
      this._imageDropdownOverlaySub.unsubscribe();
    }
  }

  private getAllMarkdownImages(): string {
    let markdownImages = '';
    this.images.forEach(i => {
      markdownImages += '\n[' + i.name + ']: ' + i.value;
    });

    return markdownImages;
  }

  private updateRenderedMarkdown(): void {
    this.renderedMarkdown = MarkdownHelper.renderWithImages({ value: this.value, valueImages: this.getAllMarkdownImages() });
  }

  private updateModel(): void {
    this.valueChanged.emit({ value: this.value, valueImages: this.getAllMarkdownImages() });
  }

  public onKeyupMarkdown(): void {
    this._markdownUpdate.next(this.value);
  }

  public onClickPreview(): void {
    this.updateRenderedMarkdown();
    this.showPreview = !this.showPreview;
  }

  public onClickHeaderOne(): void {
    this.modifySelection('# ');
  }

  public onClickHeaderTwo(): void {
    this.modifySelection('## ');
  }

  public onClickHeaderThree(): void {
    this.modifySelection('### ');
  }

  public onClickHeaderFour(): void {
    this.modifySelection('#### ');
  }

  public onClickHeaderFive(): void {
    this.modifySelection('##### ');
  }

  public onClickHeaderSix(): void {
    this.modifySelection('###### ');
  }

  public onClickBulletedList(): void {
    this.modifySelection('* ');
  }

  public onClickNumberedList(): void {
    this.modifySelection('1. ');
  }

  public onClickInsertLink(): void {
    this.modifySelection('[', '](http://google.com)');
  }

  public onClickInsertImage(): void {
    if (this._imageDropdownOverlayRef) {
      this._imageDropdownOverlayRef.dispose();
      this._imageDropdownOverlayRef = null;
    } else {
      const posStrategy = this._overlay
        .position()
        .connectedTo(this.insertImagesButton, { originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' })
        .withFallbackPosition({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' })
        .withFallbackPosition({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' })
        .withFallbackPosition({ originX: 'end', originY: 'top' }, { overlayX: 'end', overlayY: 'bottom' });

      this._imageDropdownOverlayRef = this._overlay.create({
        positionStrategy: posStrategy,
        width: 200,
        height: 200,
        hasBackdrop: true,
        backdropClass: 'mat-overlay-transparent-backdrop'
      });

      const userProfilePortal = new ComponentPortal(MarkdownEditorImageDropdownComponent);
      const imageDropDown = this._imageDropdownOverlayRef.attach(userProfilePortal).instance;
      imageDropDown.setImages(this.images);

      imageDropDown.onAddImage = () => {
        this.fileInput.nativeElement.click();
        this.closeImageDropDown();
      };

      imageDropDown.onSelectImage = (imageName: string) => {
        this.modifySelection('![alt text][' + imageName + ']', null, true);
        this.closeImageDropDown();
        this.updateModel();
      };

      imageDropDown.onDeleteImage = (imageName: string) => {
        const index = this.images.findIndex(i => i.name === imageName);
        if (index >= 0) {
          this.images.splice(index, 1);
          this.closeImageDropDown();
          this.updateRenderedMarkdown();
          this.updateModel();
        }
      };

      this._imageDropdownOverlaySub = merge(
        this._imageDropdownOverlayRef.backdropClick(),
        this._imageDropdownOverlayRef.detachments(),
        this._imageDropdownOverlayRef.keydownEvents().pipe(filter(event => event.keyCode === ESCAPE))
      ).subscribe(() => {
        this.closeImageDropDown();
      });
    }

    // this.modifySelection('![alt text](https://github.com/adam-p/markdown-here/raw/master/src/common/images/icon48.png "', '")');
  }

  private closeImageDropDown(): void {
    this._imageDropdownOverlayRef.dispose();
    this._imageDropdownOverlayRef = null;
  }

  public onClickBold(): void {
    this.modifySelection('**', '**');
  }

  public onClickItalics(): void {
    this.modifySelection('_', '_');
  }

  public onClickStrikethrough(): void {
    this.modifySelection('~~', '~~');
  }

  public onClickBlockQuote(): void {
    this.modifySelection('> ');
  }

  public onClickHorizontalRule(): void {
    this.modifySelection('---');
  }

  public onClickLineBreak(): void {
    this.modifySelection('<br>');
  }

  public onDrop(e: any): void {
    e.stopPropagation(); // Stops some browsers from redirecting.
    e.preventDefault();

    this.processFiles(e.dataTransfer.files);
  }

  public onFileInputChange(e: any): void {
    this.processFiles(e.currentTarget.files);
  }

  private processFiles(files: any): void {
    const imageType = /image.*/;
    for (let i = 0, f; (f = files[i]); i++) {
      if (f.type.match(imageType)) {
        this.encodeImageFileAsURL(f);
      }
    }
  }

  private spliceString(value: string, index: number, count: number, add: string): string {
    if (index < 0) {
      index = value.length + index;
      if (index < 0) {
        index = 0;
      }
    }
    return value.slice(0, index) + (add || '') + value.slice(index + count);
  }

  private modifySelection(prefix: string, suffix?: string, atCursor?: boolean): void {
    const element = this.textarea.nativeElement;
    let start = element.selectionStart;
    let end = element.selectionEnd;
    let length = end - start;

    if (!atCursor && length === 0) {
      const previousLines = element.value.substr(0, start).split('\n');
      const currentRow = previousLines.length - 1;
      start = end - previousLines[currentRow].length;

      const nextLines = element.value.substr(start, element.value.length).split('\n');
      end = start + nextLines[0].length;
      length = nextLines[0].length;
    }

    // TODO: Check to see if the string we have selected already has this modification so we can remove it

    const modifiedValue = prefix + element.value.substr(start, length) + (suffix ? suffix : '');
    this.value = this.spliceString(element.value, start, length, modifiedValue);

    this.updateRenderedMarkdown();
    this.updateModel();

    this.textarea.nativeElement.focus();
  }

  public encodeImageFileAsURL(file: any): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      const index = this.images.findIndex(i => i.name === file.name);
      if (index >= 0) {
        this.images[index].value = reader.result;
      } else {
        this.images.push({
          name: file.name,
          value: reader.result
        });
      }

      this.modifySelection('![alt text][' + file.name + ']', null, true);
      this.updateRenderedMarkdown();
      this.updateModel();
    };

    reader.readAsDataURL(file);
  }
}

export interface IMarkdownImage {
  name: string;
  value: string;
}

export interface IMarkdownData {
  value: string;
  valueImages: string;
}
