import { IMarkdownData } from '../components/markdown-editor/markdown-editor.component';
import * as marked from 'marked';

export class MarkdownHelper {
  static OPTIONS = {
    gfm: true,
    breaks: true
  };

  static renderWithImages(data: IMarkdownData): string {
    return marked(data.value + data.valueImages, MarkdownHelper.OPTIONS);
  }
}
