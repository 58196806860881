import { TenantsService } from '../../../../services/tenants.service';
import { BaseChartDirective } from 'ng2-charts';
import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { IChartOptions } from '../../../../interfaces/chartOptions';
import * as moment from 'moment';
import { ITenant } from '../../../../interfaces/iTenant';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-tenant-usage',
  templateUrl: './tenant-usage.component.html',
  styleUrls: ['./tenant-usage.component.css', '../card.css']
})
export class TenantUsageComponent implements OnInit, OnChanges {
  private _timeFormat = 'MM/DD/YYYY HH:mm';
  @Input() tenant: ITenant;

  public timeSpans = [
    { value: 0, timeSpan: 'PT3H', interval: 'PT15M', spanValue: 180, spanInterval: 15,  text: '3 Hours' },
    { value: 1, timeSpan: 'PT6H', interval: 'PT30M', spanValue: 360, spanInterval: 30, text: '6 Hours' },
    { value: 2, timeSpan: 'PT12H', interval: 'PT1H', spanValue: 720, spanInterval: 60, text: '12 Hours' },
    { value: 3, timeSpan: 'P1D', interval: 'PT2H', spanValue: 1440, spanInterval: 120, text: '1 Day' },
    { value: 4, timeSpan: 'P3D', interval: 'PT6H', spanValue: 4320, spanInterval: 360, text: '3 Days' },
    { value: 5, timeSpan: 'P7D', interval: 'PT14H', spanValue: 10080, spanInterval: 840, text: '7 Days' },
    { value: 6, timeSpan: 'P14D', interval: 'PT1D', spanValue: 20160, spanInterval: 1680, text: '14 Days' },
    { value: 7, timeSpan: 'P21D', interval: 'PT3D', spanValue: 28560, spanInterval: 2520, text: '21 Days' },
    { value: 8, timeSpan: 'P30D', interval: 'PT5D', spanValue: 42240, spanInterval: 3600, text: '30 Days' },
    { value: 9, timeSpan: 'P60D', interval: 'PT10D', spanValue: 85440, spanInterval: 7200, text: '60 Days' },
    { value: 10, timeSpan: 'P90D', interval: 'PT15D', spanValue: 121440, spanInterval: 10800, text: '90 Days' }    
  ];
  public selectedTimeSpan = this.timeSpans[1].value;

  public charts: IChartOptions[] = [
    {
      name: 'User Logins',
      subname: 'Failures',
      target: 'user-audit-logins',
      display: false,
      type: 'bar',
      showLegend: false,
      value: 0,
      subvalue: 0,
      datasets: [{ data: [0, 0, 0, 0, 0, 0, 0] }, { data: [0, 0, 0, 0, 0, 0, 0] }],
      colors: [
        {
          backgroundColor: 'rgba(39, 56, 206, 0.5)',
          borderColor: 'rgb(24, 35, 131)',
          pointBackgroundColor: 'rgb(39, 56, 206)',
          pointBorderColor: 'rgba(255, 255, 255, 0.8)',
          pointHoverBackgroundColor: 'rgb(39, 56, 206)',
          pointHoverBorderColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          backgroundColor: 'rgba(220, 0, 0, 0.5)',
          borderColor: 'rgb(200, 0, 0)',
          pointBackgroundColor: 'rgb(220, 0, 0)',
          pointBorderColor: 'rgba(255, 255, 255, 0.8)',
          pointHoverBackgroundColor: 'rgb(220, 0, 0)',
          pointHoverBorderColor: 'rgba(255, 255, 255, 0.8)'
        }
      ],
      labels: [],
      options: {
        responsive: true,
        elements: { line: { tension: 0, borderWidth: 1 } },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                parser: this._timeFormat,
                // round: 'day'
                tooltipFormat: 'll HH:mm'
              },
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9,
                padding: 4,
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                  if (Math.floor(label) === label) {
                    return label;
                  }
                }
              }
            }
          ]
        }
      }
    },
    {
      name: 'Total Contracts',
      target: 'total-documents',
      display: false,
      type: 'bar',
      showLegend: false,
      value: 0,
      subvalue: 0,
      datasets: [{ data: [0, 0, 0, 0, 0, 0, 0] }, { data: [0, 0, 0, 0, 0, 0, 0] }],
      colors: [
        {
          backgroundColor: 'rgba(39, 56, 206, 0.5)',
          borderColor: 'rgb(24, 35, 131)',
          pointBackgroundColor: 'rgb(39, 56, 206)',
          pointBorderColor: 'rgba(255, 255, 255, 0.8)',
          pointHoverBackgroundColor: 'rgb(39, 56, 206)',
          pointHoverBorderColor: 'rgba(255, 255, 255, 0.8)'
        },
        {
          backgroundColor: 'rgba(220, 0, 0, 0.5)',
          borderColor: 'rgb(200, 0, 0)',
          pointBackgroundColor: 'rgb(220, 0, 0)',
          pointBorderColor: 'rgba(255, 255, 255, 0.8)',
          pointHoverBackgroundColor: 'rgb(220, 0, 0)',
          pointHoverBorderColor: 'rgba(255, 255, 255, 0.8)'
        }
      ],
      labels: [],
      options: {
        responsive: true,
        elements: { line: { tension: 0, borderWidth: 1 } },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                parser: this._timeFormat,
                // round: 'day'
                tooltipFormat: 'll HH:mm'
              },
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9,
                padding: 4,
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                  if (Math.floor(label) === label) {
                    return label;
                  }
                }
              }
            }
          ]
        }
      }
    }
  ];

  constructor(
    private _tenantsService: TenantsService) {}

  ngOnInit(): void {
    //this.getData(this.tenant.id);
  }

  ngOnChanges(changes: SimpleChanges) {
    // Check to see if the Tenant input is changing and has a valid value
    if (changes['tenant'] && changes['tenant'].currentValue) {
      this.getData(this.tenant.id);
    }
  }

  private getData(id: number): void {
    this.charts.forEach(chart => {
      if (chart.target === 'user-audit-logins') {
        this._tenantsService.getUserLoginsForTenant(id).then(resp => {
          this.postProcessChartData(chart, resp, 'login');
          chart.display = true;
        });
      }
      else if (chart.target === 'total-documents') {
        this._tenantsService.getAllContractsForTenant(id).then(resp => {
          this.postProcessChartData(chart, resp, 'document');
          chart.display = true;
        })
      }
    });
  }

  private postProcessChartData(chart: IChartOptions, data: any, chartType: string): void {
    const selectedTimeSpan = this.timeSpans[this.selectedTimeSpan];
    const intervals = selectedTimeSpan.spanValue / selectedTimeSpan.spanInterval;
    const span = 'minutes';
    const maxDateAgo = moment
      .utc()
      .subtract(selectedTimeSpan.spanValue, span);

    const orderedData = data
      .filter(x => {
        const createdDate = moment.utc(x.createdDate);
        return createdDate.isSameOrAfter(maxDateAgo);
      })
      .sort(
      (a: any, b: any) =>
        moment(a.date)
          .toDate()
          .getTime() -
        moment(b.date)
          .toDate()
          .getTime()
    );

    const successfulDataset = {
      label: chart.name,
      fill: true,
      data: []
    };

    const failureDataset = {
      label: chart.name,
      fill: true,
      data: []
    };

    let successTotal = 0;
    let failedTotal = 0;
    const labels: Date[] = [];

    // Create label - 1 entry
    labels.push(moment.utc().subtract(selectedTimeSpan.spanValue + selectedTimeSpan.spanInterval, span).toDate());
    successfulDataset.data.push(0);
    failureDataset.data.push(0);

    // Create middle labels
    for (let i = intervals; i > 0; --i) {
      const date = moment
        .utc()
        .subtract(i * selectedTimeSpan.spanInterval, span);
      labels.push(date.toDate());
      successfulDataset.data.push(0);
      failureDataset.data.push(0);
    }

    // Create label + 1 entry
    labels.push(moment.utc().add(selectedTimeSpan.spanInterval, span).toDate());
    successfulDataset.data.push(0);
    failureDataset.data.push(0);

    for (let i = 0; i < orderedData.length; ++i) {
      const createdDate = moment.utc(orderedData[i].createdDate);
      const valueAgo = createdDate.diff(maxDateAgo, span);
      const index = Math.floor(valueAgo / selectedTimeSpan.spanInterval) + 1; // Add +1 to offset the padding (label - 1 and label + 1)

      if (chartType == 'login'){
        if (orderedData[i].isSuccess) {
          successfulDataset.data[index]++;
          successTotal++;
        } else {
          failureDataset.data[index]++;
          failedTotal++;
        }
      }
      else {
        successfulDataset.data[index]++;
        successTotal++;
      }
    }

    chart.labels = labels;
    chart.datasets[0] = successfulDataset;
    if (chartType == 'login') {
      chart.datasets[1] = failureDataset;
      chart.subvalue = failedTotal;
    }
    chart.value = successTotal;
  }

  public onClickRefresh(): void {
    this.getData(this.tenant.id);
  }

  public onTimeSpanSelectionChange(): void {
    this.charts.forEach(chart => {
      chart.timeSpan = this.timeSpans[this.selectedTimeSpan].timeSpan;
      chart.interval = this.timeSpans[this.selectedTimeSpan].interval;
    });

    this.getData(this.tenant.id);
  }
}