import { IMarkdownImage } from './../markdown-editor.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-markdown-editor-image-dropdown-component',
  templateUrl: './image-dropdown.component.html',
  styleUrls: ['./image-dropdown.component.scss']
})
export class MarkdownEditorImageDropdownComponent implements OnInit {
  public images: IMarkdownImage[] = [];
  public onAddImage: Function;
  public onSelectImage: Function;
  public onDeleteImage: Function;

  constructor() {

  }

  public ngOnInit(): void {

  }

  public setImages(images: IMarkdownImage[]): void {
    this.images = images;
  }

  public onClickAddImage(): void {
    this.onAddImage();
  }

  public onClickRow(image: IMarkdownImage): void {
    this.onSelectImage(image.name);
  }

  public onClickDeleteImage(image: IMarkdownImage, index: number): void {
    this.onDeleteImage(image.name);
  }
}
