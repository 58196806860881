import {
  Component,
  Input,
  OnInit,
  OnChanges,
  ViewEncapsulation,
  ChangeDetectorRef
} from '@angular/core';
import { TenantsService } from '../../../services/tenants.service';
import { ITenant } from '../../../interfaces/iTenant';
import { debug } from 'util';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-licenses-chart',
  templateUrl: './licenses.chart.component.html',
  styleUrls: ['./licenses.chart.component.css']
})
export class LicensesChartComponent implements OnInit, OnChanges {
  @Input() currentTenant: ITenant;
  public usedCount: number;
  public listcharts: any[] = [];
  public licenseChartLabels1: string[] = ['Full Licenses', 'Remaining'];
  public licenseChartLabels2: string[] = ['Submitter Licenses', 'Remaining'];
  public licenseChartLabels3: string[] = ['ReadOnly Licenses', 'Remaining'];


  public licenseChartData1: number[] = [];
  public licenseChartData2: number[] = [];
  public licenseChartData3: number[] = [];
  public licenseChartType: string = 'doughnut';
 
  private doughnutChartColors1: any[] = [
    {
      backgroundColor: ['#87CEFA', '#0000FF'],
      hoverBackgroundColor: ['#87CEFA', '#0000FF']
    }
  ];
  private doughnutChartColors2: any[] = [
    {
      backgroundColor: ['#EE82EE', '#cc65fe'],
      hoverBackgroundColor: ['#EE82EE', '#cc65fe']
    }
  ];
  private doughnutChartColors3: any[] = [
    {
      backgroundColor: ['#F0E68C', '#FF8C00'],
      hoverBackgroundColor: ['#F0E68C', '#FF8C00']
    }
  ];




  constructor(private _tenantsService: TenantsService) {}

  ngOnInit(): void {
 
    this.listcharts.push({ data: this.licenseChartData1, colors: this.doughnutChartColors1, labels: this.licenseChartLabels1, type:"full" });
    this.listcharts.push({ data: this.licenseChartData2, colors: this.doughnutChartColors2, labels: this.licenseChartLabels2, type:"submitter" });
    this.listcharts.push({ data: this.licenseChartData3, colors: this.doughnutChartColors3, labels: this.licenseChartLabels3, type:"readonly"});
    console.log(this.listcharts);
  }

  ngOnChanges(changes: any): void {
    for (const property in changes) {
      if (changes.hasOwnProperty(property)) {
        const change = changes[property];
        if (!change.firstChange) {
          this.getUsedLicenses();
        }
      }
    }
  }

  private getUsedLicenses(): void {
    
    // const tenantid = this.tenant.rowKey;
    this._tenantsService.getUserCount(this.currentTenant.id).then(resp => {    
     // this.usedCount = resp;
 
      
     
      this.licenseChartData1 = [
        resp.fullLicenses,
        this.currentTenant.licensesFull-resp.fullLicenses
   
      ];
      this.licenseChartData2 = [
        resp.submitterLicenses,
        this.currentTenant.licensesSubmitterFull-resp.submitterLicenses
 
      ];
      this.licenseChartData3 = [
        resp.readOnlyLicenses,
        this.currentTenant.readonlyLicenses - resp.readOnlyLicenses

      ];
      this.listcharts.forEach(eachObj => {
        if (eachObj.type == 'full')
          eachObj.data = this.licenseChartData1;
        if (eachObj.type == 'submitter')
          eachObj.data = this.licenseChartData2;
        if (eachObj.type == 'readonly')
          eachObj.data = this.licenseChartData3;
      });
     
    });
  }

  // events
  public licenseChartClicked(e: any): void {
    console.log(e);
  }

  public licenseChartHovered(e: any): void {
    console.log(e);
  }
}
