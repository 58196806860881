﻿import { environment } from './../environments/environment';
/* ===== app/services/backup.service.ts ===== */
import { Injectable, Inject } from '@angular/core';
import { AdalService } from './adal/adal.service';
import { INews, NewsPostType } from '../interfaces/iNews';
import { v4 } from 'uuid';
import * as moment from 'moment';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';

@Injectable()
export class NewsService {
  private _url: string = '';
  private _options: any;

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private _http: HttpClient
  ) {
    this._url = environment.restTarget + '/api/news';
    this._options = { observe: 'response' };
  }

  public getEmpty(): INews {
    const news: INews = {
      id: 0,
      type: NewsPostType.IndustryNews,
      title: '',
      subject: '',
      article: '',
      markdown: '',
      postDate: moment().utc().toISOString(),
      isActive: true,
      updatedBy: '',
      createdBy: ''
    };

    return news;
  }

  public getAll(): Promise<INews[]> {
    return new Promise((resolve, reject) => {
      this._http.get<INews[]>(this._url + '/all', this._options).subscribe(
        results => {
          const data = results as HttpResponse<INews[]>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getById(id: number | null): Promise<INews> {
    if (!id) {
      return Promise.reject('No News Id.');
    }

    return new Promise((resolve, reject) => {
      const target = this._url + '/details/' + id;
      this._http.get<INews>(target, this._options)
      .subscribe(
        result => {
          const data = result as HttpResponse<INews>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public post(news: INews): Promise<any> {
    if (!news) {
      return Promise.reject('No News data.');
    }

    return new Promise((resolve, reject) => {
      this._http.post<INews>(this._url, JSON.stringify(news), this._options).subscribe(
        result => {
          const data = result as HttpResponse<INews>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public put(news: INews): Promise<any> {
    if (!news) {
      return Promise.reject('No News Id.');
    }

    return new Promise((resolve, reject) => {
      this._http.put<INews>(this._url + '/' + news.id, JSON.stringify(news), this._options).subscribe(
        result => {
          const data = result as HttpResponse<INews>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public delete(id: Number): Promise<any> {
    if (!id) {
      return Promise.reject('No News Id.');
    }

    return new Promise((resolve, reject) => {
      const target = this._url + '/delete/' + id;
      this._http.delete<INews>(target, this._options)
      .subscribe(
        result => {
          const data = result as HttpResponse<INews>;
          resolve(data.body);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}