﻿import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild } from '@angular/core';
import { TenantsService } from '../../../services/tenants.service';
import * as moment from 'moment';
import { IChartColor } from '../../../interfaces/iChartColor';
import { BaseChartDirective } from 'ng2-charts';
import { IChartOptions } from '../../../interfaces/chartOptions';

interface Item {
  text: string;
  value: string;
  frameText: string;
  frameFooterText: string;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-tenant-data',
  templateUrl: './tenant-data.component.html',
  styleUrls: ['./tenant-data.component.css', '../card.css']
})
export class TenantDataComponent implements OnInit {
  private _timeFormat = 'MM/DD/YYYY HH:mm';

  public timeSpans = [
    { value: 0, timeSpan: 7, text: '7 days' },
    { value: 1, timeSpan: 14, text: '14 days' },
    { value: 2, timeSpan: 30, text: '30 days' },
    { value: 3, timeSpan: 90, text: '90 days' }
  ];
  public selectedTimeSpan = this.timeSpans[0].value;

  public charts: IChartOptions[] = [
    {
      name: 'Total Licenses',
      subname: 'Subtotal',
      target: 'licenses-over-time',
      display: false,
      type: 'bar',
      showLegend: false,
      value: 0,
      subvalue: 0,
      datasets: [{ data: [0, 0, 0, 0, 0, 0, 0] }],
      colors: [
        {
          backgroundColor: 'rgba(39, 56, 206, 0.5)',
          borderColor: 'rgb(24, 35, 131)',
          pointBackgroundColor: 'rgb(39, 56, 206)',
          pointBorderColor: 'rgba(255, 255, 255, 0.8)',
          pointHoverBackgroundColor: 'rgb(39, 56, 206)',
          pointHoverBorderColor: 'rgba(255, 255, 255, 0.8)'
        }
      ],
      labels: [],
      options: {
        responsive: true,
        elements: { line: { tension: 0, borderWidth: 1 } },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                parser: this._timeFormat,
                // round: 'day'
                tooltipFormat: 'll HH:mm'
              },
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9,
                padding: 4,
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                  if (Math.floor(label) === label) {
                    return label;
                  }
                }
              }
            }
          ]
        }
      }
    },
    {
      name: 'Total Tenants',
      subname: 'Subtotal',
      target: 'tenants-over-time',
      display: false,
      type: 'bar',
      showLegend: false,
      value: 0,
      subvalue: 0,
      datasets: [{ data: [0, 0, 0, 0, 0, 0, 0] }],
      colors: [
        {
          backgroundColor: 'rgba(206, 153, 39, 0.5)',
          borderColor: 'rgb(134, 100, 26)',
          pointBackgroundColor: 'rgb(206, 153, 39)',
          pointBorderColor: 'rgba(255, 255, 255, 0.8)',
          pointHoverBackgroundColor: 'rgb(206, 153, 39)',
          pointHoverBorderColor: 'rgba(255, 255, 255, 0.8)'
        }
      ],
      labels: [],
      options: {
        responsive: true,
        elements: { line: { tension: 0, borderWidth: 1 } },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                parser: this._timeFormat,
                // round: 'day'
                tooltipFormat: 'll HH:mm'
              },
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: `'Roboto', sans-serif`,
                fontSize: 9,
                padding: 4,
                beginAtZero: true,
                userCallback: function(label, index, labels) {
                  if (Math.floor(label) === label) {
                    return label;
                  }
                }
              }
            }
          ]
        }
      }
    }
  ];

  // public listItems: Array<Item> = [
  //   { text: '7d', value: '7', frameText: 'Last 7 days', frameFooterText: 'days' },
  //   { text: '14d', value: '14', frameText: 'Last 14 days', frameFooterText: 'days' },
  //   { text: '1m', value: '30', frameText: 'Last 30 days', frameFooterText: 'days' },
  //   { text: '3m', value: '90', frameText: 'Last 90 days', frameFooterText: 'weeks' }
  // ];
  // public selectedItem: Item;
  // public defaultItem: { text: string; value: string } = { text: 'Select timespan...', value: '' };

  constructor(private tenantsService: TenantsService) {
    // this.selectedItem = this.listItems[0];
  }

  ngOnInit(): void {
    this.getData();
  }

  private getData(): void {
    this.tenantsService.getAll().then(resp => {
      this.charts.forEach(chart => {
        if (chart.target === 'licenses-over-time') {
          this.postProcessLicenseData(chart, resp);
          chart.display = true;
        } else if (chart.target === 'tenants-over-time') {
          this.postProcessTenantCountData(chart, resp);
          chart.display = true;
        }
      });
    });
  }

  private postProcessLicenseData(chart: IChartOptions, data: any[]): void {
    const spanAgo = this.timeSpans[this.selectedTimeSpan].timeSpan;
    const span = 'days';
    const today = moment.utc().startOf('day');
    const maxDateAgo = moment
      .utc()
      .startOf('day')
      .subtract(spanAgo, span);

    const orderedData = data.sort(
      (a: any, b: any) =>
        moment(a.createdDate)
          .toDate()
          .getTime() -
        moment(b.createdDate)
          .toDate()
          .getTime()
    );

    let startTotal = 0;
    let lastIndex = 0;
    for (let i = 0; i < orderedData.length; ++i) {
      const createdDate = moment.utc(orderedData[i].createdDate);
      if (createdDate.isSameOrAfter(maxDateAgo)) {
        break;
      } else {
        startTotal += orderedData[i].licensesFull;
        lastIndex = i;
      }
    }

    const dataset = {
      label: chart.name,
      fill: true,
      data: []
    };

    let currentTotal = 0;
    const labels: Date[] = [];
    for (let i = spanAgo; i > 0; --i) {
      const date = moment
        .utc()
        .startOf('day')
        .subtract(i, span);
      labels.push(date.toDate());
      dataset.data.push(0);
    }

    for (let i = lastIndex + 1; i < orderedData.length; ++i) {
      currentTotal += orderedData[i].licensesFull;

      const createdDate = moment.utc(orderedData[i].createdDate);
      const valueAgo = createdDate.diff(maxDateAgo, span) - 1;
      dataset.data[valueAgo] += orderedData[i].licensesFull;
    }

    chart.labels = labels;
    chart.datasets[0] = dataset;
    chart.value = startTotal + currentTotal;
    chart.subvalue = currentTotal;
    chart.subname = 'Last ' + this.timeSpans[this.selectedTimeSpan].text;
  }

  private postProcessTenantCountData(chart: IChartOptions, data: any): void {
    const orderedData = data.sort(
      (a: any, b: any) =>
        moment(a.createdDate)
          .toDate()
          .getTime() -
        moment(b.createdDate)
          .toDate()
          .getTime()
    );

    const spanAgo = this.timeSpans[this.selectedTimeSpan].timeSpan;
    const span = 'days';
    const today = moment.utc().startOf('day');
    const maxDateAgo = moment
      .utc()
      .startOf('day')
      .subtract(spanAgo, span);

    let startTotal = 0;
    let lastIndex = 0;
    for (let i = 0; i < orderedData.length; ++i) {
      const createdDate = moment.utc(orderedData[i].createdDate);
      if (createdDate.isSameOrAfter(maxDateAgo)) {
        break;
      } else {
        startTotal++;
        lastIndex = i;
      }
    }

    const dataset = {
      label: chart.name,
      fill: true,
      data: []
    };

    let currentTotal = 0;
    const labels: Date[] = [];
    for (let i = spanAgo; i > 0; --i) {
      const date = moment
        .utc()
        .startOf('day')
        .subtract(i, span);
      labels.push(date.toDate());
      dataset.data.push(0);
    }

    for (let i = lastIndex + 1; i < orderedData.length; ++i) {
      currentTotal++;

      const createdDate = moment.utc(orderedData[i].createdDate);
      const valueAgo = createdDate.diff(maxDateAgo, span) - 1;
      dataset.data[valueAgo] += 1;
    }

    chart.labels = labels;
    chart.datasets[0] = dataset;
    chart.value = startTotal + currentTotal;
    chart.subvalue = currentTotal;
    chart.subname = 'Last ' + this.timeSpans[this.selectedTimeSpan].text;
  }

  public onClickRefresh(): void {
    this.getData();
  }

  public onTimeSpanSelectionChange(): void {
    this.getData();
  }

  // public onClickZoomButton(item: any): void {
  //   this.selectedItem = item;
  //   this.dayLength = parseInt(item.value);
  //   this.getData();
  // }

  // // events
  // public licensesCountChartClicked(e: any): void {
  //   console.log(e);
  // }

  // public licensesCountChartHovered(e: any): void {
  //   console.log(e);
  // }
}
