import { Component, OnInit } from '@angular/core';
import { AppInsightsService } from '../../services/app.insights.service';
import { TenantsService } from '../../services/tenants.service';
import { AdalService } from '../../services/adal/adal.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  userCount = 0;
  avgRequest = 0;
  tenants: any = null;

  constructor(private _adalService: AdalService, private _tenantsService: TenantsService, private _appInsights: AppInsightsService) {}

  public ngOnInit(): void {
    if (this._adalService.isAuthenticated()) {
      this._tenantsService.getAll().then(result => {
        this.userCount = result.length;
      });
    }
  }

  public getUserCount(): void {}

  public getAvgServerRequest(): void {
    this._appInsights.getServerRequestsDuration().then(resp => {
      this.avgRequest = resp;
    });
  }

  public isAuthenticated(): boolean {
    return this._adalService.isAuthenticated();
  }
}
