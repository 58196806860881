import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { AdalService } from './adal.service';

@Injectable()
export class AdalInterceptor implements HttpInterceptor {
  private _baseUrl: string;

  constructor(@Inject('BASE_URL') baseUrl: string, public adalService: AdalService) {
    this._baseUrl = baseUrl;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const resource = this.adalService.GetResourceForEndpoint(request.url);
    let obs: Observable<HttpEvent<any>>;

    if (resource) {
      if (this.adalService.userInfo.authenticated) {
        obs = this.adalService.acquireToken(resource).flatMap((token: string) => {
          // Clone the request to add the new headers.
          const authReq = request.clone({
            headers: request.headers
              .set('authorization', 'Bearer ' + token)
              .set('access-control-allow-origin', '*')
              .set('accept', 'application/json')
              .set('content-type', 'application/json')
              // .set('X-XSRF-TOKEN', token ) // TODO: Need the token from the APIs
          });

          return next.handle(authReq);
        });
      } else {
        return Observable.throw(new Error('User Not Authenticated.'));
      }
    } else {
      // Handle the request
      obs = next.handle(request);
    }

    return obs;
  }
}
