﻿import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { TenantsService } from '../../../services/tenants.service';
import * as moment from 'moment';
import { IChartColor } from '../../../interfaces/iChartColor';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-users-count',
  templateUrl: './users.count.component.html',
  styleUrls: ['./users.count.component.css']
})
export class UsersCountComponent implements OnInit {
  @ViewChild('chart') chart: BaseChartDirective;

  public display: boolean = false;
  public dayLength = 24;

  public hourCalc: any;
  public dataCalc: any;
  public data: any[] = [{ data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }];
  public labels: string[] = [];
  public colors: any[] = [
    {
      backgroundColor: '#32639e',
      borderColor: '#294360',
      pointBackgroundColor: '#32639e',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#4189c7',
      pointHoverBorderColor: 'fff'
    }
  ];
  public options: any = {
    responsive: true,
    elements: { line: { tension: 0 } },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            userCallback: function(label, index, labels) {
              if (Math.floor(label) === label) {
                return label;
              }
            }
          }
        }
      ]
    }
  };
  public showLegend = false;

  constructor(private tenantService: TenantsService) {}

  ngOnInit(): void {
    this.getData();
  }

  private clearData(): void {
    this.data = [];
  }

  private getData(): void {
    this.tenantService.getUserLogins().then(resp => {
      this.clearData();
      this.postProcessData(resp);
      this.display = true;
    });
  }

  postProcessData(data: any): void {
    const newLoginCount = [];

    this.labels.length = 0;
    for (let i = 0; i < this.dayLength; ++i) {
      newLoginCount.push(0);
    }
    const orderedData = data.sort(
      (a: any, b: any) =>
        moment(a.date)
          .toDate()
          .getTime() -
        moment(b.date)
          .toDate()
          .getTime()
    );
    for (let i = this.dayLength - 1; i >= 0; --i) {
      const date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      const dateUtc = moment.utc(date).toDate();
      const local = moment(dateUtc)
        .local()
        .format('YYYY-MM-DD HH:mm:ss');
      this.hourCalc = moment(local).subtract(i, 'hours');
      const displayDate = this.hourCalc.format('h a');
      this.labels.push(displayDate);

      let totalCount = 0;
      const users = [];
      for (let j = 0; j < orderedData.length; ++j) {
        const createdDate = moment.utc(orderedData[j].date).toDate();
        const createdDateOffset = this.adjustForTimezone(createdDate);
        if (!moment(createdDateOffset).isBefore(this.hourCalc, 'day')) {
          if (moment(createdDateOffset).isSame(this.hourCalc, 'hour')) {
            if (users.indexOf(orderedData[j].username) === -1) {
              totalCount += 1;
              users.push(orderedData[j].username);
            }
          }
        }
      }
      newLoginCount[i] = totalCount;
    }
    newLoginCount.reverse();
    this.data = this.data = [{ data: newLoginCount }];
  }

  // events
  public usersCountChartClicked(e: any): void {
    console.log(e);
  }

  public usersCountChartHovered(e: any): void {
    console.log(e);
  }

  public adjustForTimezone(date: Date): Date {
    const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date;
  }
}
