import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-contacts-new-dialog',
  templateUrl: './contacts.new.component.html',
  styleUrls: ['./contacts.new.component.scss']
})
export class ContactsNewDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ContactsNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}