import { EnumHelper } from './../../../../helpers/enum.helper';
import { ILookup } from './../../../../interfaces/lookup';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { BackupsService } from './../../../../services/backups.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ITenant } from '../../../../interfaces/iTenant';
import { DataSource } from '@angular/cdk/collections';
import { IBackup, BackupType } from '../../../../interfaces/iBackup';
import * as moment from 'moment';
import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';

@Component({
  selector: 'app-tenant-detail-backups',
  templateUrl: 'backups.component.html',
  styleUrls: ['./backups.component.scss']
})
export class TenantDetailBackupsComponent implements OnInit, OnChanges {
  @Input() tenant: ITenant;

  public backupColumns = ['type', 'date', 'command'];
  private _backupData: BehaviorSubject<IBackup[]> = new BehaviorSubject<
    IBackup[]
  >([]);
  public backupDataSource = new BackupDataSource(
    this._backupData.asObservable()
  );

  public allBackupTypes: ILookup[] = [];

  constructor(private _backupsService: BackupsService) {
    this.allBackupTypes = EnumHelper.toLookupDictionary(BackupType);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // Check to see if the Tenant input is changing and has a valid value
    if (changes['tenant'] && changes['tenant'].currentValue) {
      this._backupsService
        .getBackupsListById(this.tenant.id)
        .then((data: IBackup[]) => {
          const now = moment();
          const startOfToday = moment().startOf('day');
          const sevenDaysAgo = moment(startOfToday).subtract(7, 'days');
          const fourWeeksAgo = moment(sevenDaysAgo).subtract(4, 'weeks');
          const startOfPrevMonth = moment(sevenDaysAgo).startOf('month');
          const startOfYear = moment().startOf('year');
          const backups: IBackup[] = [];

          for (let i = 0; i < data.length; ++i) {
            const backup = data[i];
            const backupDate = moment(backup.timestamp);

            // Check for Hourly backups
            if (backupDate.isBefore(now) && backupDate.isAfter(startOfToday)) {
              backup.type = BackupType.Hourly;
              backups.push(backup);
            }

            // Get the last day here since we need to wait for the first push
            const startOfLastDay = moment(
              backups[backups.length - 1].timestamp
            ).startOf('day');

            // Check for Daily backups
            if (
              backupDate.isBefore(startOfLastDay) &&
              backupDate.isAfter(sevenDaysAgo)
            ) {
              backup.type = BackupType.Daily;
              backups.push(backup);
            }

            // Get the Week of the last day
            const startOfLastWeek = moment(startOfLastDay)
              .subtract(1, 'days')
              .startOf('week')
              .add(1, 'days');

            // Check for Weekly backups
            if (
              backupDate.isBefore(startOfLastWeek) &&
              backupDate.isAfter(fourWeeksAgo) &&
              backupDate.isBefore(sevenDaysAgo)
            ) {
              backup.type = BackupType.Weekly;
              backups.push(backup);
            }

            // Get the Month of the last day
            const startOfLastMonth = moment(startOfLastDay).startOf('month');

            // Check for Monthly backups
            if (
              backupDate.isBefore(startOfLastMonth) &&
              backupDate.isAfter(startOfYear) &&
              backupDate.isBefore(fourWeeksAgo)
            ) {
              backup.type = BackupType.Monthly;
              backups.push(backup);
            }
          }

          this._backupData.next(backups);
        });
    }
  }

  restoreBackup(row: any): void {
    const body = { dateTime: row['name'], email: this.tenant.adminEmail };
    this._backupsService
      .restoreBackup(this.tenant.id, JSON.stringify(body))
      .then(() => {});
  }

  downloadBackup(row: any): void {
    this._backupsService
      .downloadBackup(this.tenant.id, row['name'])
      .then(() => {});
  }
}

export class BackupDataSource extends DataSource<any> {
  private _data: Observable<IBackup[]>;

  constructor(data: Observable<IBackup[]>) {
    super();
    this._data = data;
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<IBackup[]> {
    return this._data;
  }

  disconnect() {}
}
