﻿import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { TenantsService } from '../../services/tenants.service';
import { ITenant } from '../../interfaces/iTenant';
import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/Rx';
import { MatDialog, MatSnackBar, MatTableDataSource, MatSort } from '@angular/material';
import { TenantsNewDialogComponent } from './new/tenants.new.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.css']
})
export class TenantsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  private _isCommand: boolean = false;

  public tenantColumns = ['suspended', 'customerId', 'name', 'adminEmail', 'adminName', 'createdDate'];
  public dataSource = new MatTableDataSource([]);

  constructor(private _tenantsService: TenantsService, private _router: Router, public dialog: MatDialog, private _snackBar: MatSnackBar) {}

  public ngOnInit(): void {
    this.getData();
  }

  public ngAfterViewInit(): void {}

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  private getData(): void {
    this._tenantsService.getAll().then(results => {
      this.dataSource = new MatTableDataSource(results);
      this.dataSource.sort = this.sort;
    });
  }

  public onClickRow(row: ITenant) {
    this._router.navigate(['/tenants/' + row.id]);
  }

  onOpenNewTenant(): void {
    const dialogRef = this.dialog.open(TenantsNewDialogComponent, {
      width: '700px',
      data: this._tenantsService.getEmpty(),
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(newTenant => {
      if (newTenant) {
        this._snackBar.open('Created!', 'Close message', {
          duration: 2000
        });
        this._router.navigate(['/tenants/' + newTenant.id]);
      }
    });
  }
}
